<template>
  <div id="home" v-wechat-title="`${courseInfo.name}_京蒙教育协作智慧平台`">
    <div class="course-detail">
      <div class="detail-container">
        <div class="course-info flex-layout">
          <!-- 左侧课程封面图 -->
          <img class="cover" :src="downloadURL + courseInfo.cover + '&width=450'"
            :onerror="$store.state.course.defaultCourseBigCover[`${$route.query.index % 6}`]" />
          <!-- 右侧课程信息 -->
          <div class="base-info-right">
            <div class="info-part flex-between">
              <div>
                <p class="title">{{ courseInfo && courseInfo.name }}</p>
                <p class="project-name">{{ courseInfo.project && courseInfo.project.name }}项目</p>
                <p class="teacher" v-if="courseInfo.lessonsPattern == 1">授课老师：{{ asyncTeacherName }}</p>
                <p class="teacher" v-else>授课老师：{{ courseInfo.teacherInfo && courseInfo.teacherInfo.name }}</p>
                <p class="time">课程时间：{{ courseInfo && courseInfo.timeScope }}</p>
              </div>
              <!-- <el-button type="primary" class="into-course btn" :class="[markMap[courseInfo.mark].class]"
                @click="golive(courseInfo)">
                {{markMap[courseInfo.mark].text}}
              </el-button> -->
            </div>
            <!-- 统计数据 -->
            <div class="count-data flex-align-around" v-if="$store.state.user.role == 'expert'">
              <div v-for="(item, index) in countData" :key="index"
                v-show="(item.title !== 'totalSign' && courseInfo.lessonsPattern == 1) || courseInfo.lessonsPattern != 1">
                <!-- 非学习时长的其他项 -->
                <span class="count" v-if="item.title !== 'totalLearn'">{{ item.score }}<i>{{ item.unit }}</i></span>
                <!-- 学习时长 -->
                <span class="count" v-else>
                  <span v-if="courseInfo.totalLearn >= 3600">{{ courseInfo.totalLearn | transformTimeFormat('hour')
                  }}<i>小时</i></span>
                  <span v-if="courseInfo.totalLearn >= 60">{{ courseInfo.totalLearn | transformTimeFormat('min')
                  }}<i>分钟</i></span>
                  <span>{{ courseInfo.totalLearn | transformTimeFormat('second') }}<i>秒</i></span>
                </span>
                <br />{{ item.title | handleCountData(courseInfo.lessonsPattern, courseInfo.classType) }}
              </div>
            </div>
            <div class="count-data flex-align-around"
              v-if="$store.state.user.role == 'student' || $store.state.user.role == 'teacher' || $store.state.user.role == 'normal'">
              <div v-for="(item, index) in countDatas" :key="index">
                <span v-if="item.title != 'playbackTime' && item.title != 'liveTime'" class="count">{{ item.value }}<i>{{
                  item.unit }}</i></span>
                <span v-else class="count">
                  {{ item.value | MinFormat('min') }}<i>{{ item.unit }}</i>
                  {{ item.value | MinFormat('second') }}<i>秒</i>
                </span>
                <br />{{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <!-- 下部分 -->
        <div class="detail-info flex-between">
          <div class="catalog">
            <el-tabs v-model="currentTab" :stretch="true" @click="currentTab = tab.title">
              <el-tab-pane :label="tab.name" :name="tab.title" v-for="tab in tabs" :key="tab.title">
                <component class="tab" v-bind:is="currentTabComponent" :courseDesc="courseInfo" @getAsyncTeacherInfo="getAsyncTeacherInfo"
                  @getAsyncTeacherName="getAsyncTeacherName" v-if="`tab-${tab.title.toLowerCase()}` == currentTabComponent">
                </component>
              </el-tab-pane>
            </el-tabs>
          </div>
          <div class="aside">
            <div class="teacher-info" v-if="courseInfo.lessonsPattern == 1">
              <h4>授课老师</h4>
              <div class="name-part">
                <img :src="downloadURL + asyncTeacherInfo.cover" alt=""
                  :onerror="$store.state.user.defaultAvatar" class="avatar" />
                <span class="name">{{ asyncTeacherInfo.name }}</span>
                <!-- <span class="title">{{ courseInfo.teacherInfo && courseInfo.teacherInfo.title }}</span> -->
              </div>
              <p class="discribe">
                {{ asyncTeacherInfo.introduce || '暂无简介' }}
              </p>
            </div>
            <div class="teacher-info" v-else>
              <h4>授课老师</h4>
              <div class="name-part">
                <img :src="courseInfo.teacherInfo && downloadURL + courseInfo.teacherInfo.avatar" alt=""
                  :onerror="$store.state.user.defaultAvatar" class="avatar" />
                <span class="name">{{ courseInfo.teacherInfo && courseInfo.teacherInfo.name }}</span>
                <span class="title">{{ courseInfo.teacherInfo && courseInfo.teacherInfo.title }}</span>
              </div>
              <p class="discribe">
                {{ courseInfo.teacherInfo && courseInfo.teacherInfo.introduce || '暂无简介' }}
              </p>
            </div>
            <tab-comment></tab-comment>
          </div>
        </div>
      </div>
    </div>
    <!-- <Footer></Footer> -->
    <ToLiveMode :isVisible="downloadVisible" :liveLessonId="courseInfo.liveLessonId" :classType="courseInfo.classType"
      top="15vh" @downClient="handleDownLoad">
    </ToLiveMode>
  </div>
</template>
<script>
import Introduce from "./component/course_introduce"
import Catalog from "./component/course_catalog"
import Task from "./component/course_task"
import Discuss from "./component/course_discuss"
import comment from "./component/course_comment"
import Achievement from "./component/course_achievement"

export default {
  props: ["query"],
  components: {
    'tab-introduce': Introduce,
    'tab-catalog': Catalog,
    'tab-achievement': Achievement,
    'tab-task': Task,
    'tab-discuss': Discuss,
    'tab-comment': comment,
  },
  computed: {
    currentTabComponent: function () {
      return "tab-" + this.currentTab.toLowerCase();
    },

  },
  data() {
    return {
      from: 'course',
      courseInfo: { //课程信息
        mark: 2,
      },
      currentTab: 'Catalog',
      tabs: [{
        title: "Catalog",
        name: '课程目录'
      },
      {
        title: "Introduce",
        name: '简介'
      },
        // {
        //   title: "Achievement",
        //   name: '成果'
        // },

        // {
        //   title: "Task",
        //   name: '作业'
        // },
        // {
        //   title: "Discuss",
        //   name: '讨论'
        // }
      ],
      countDatas: [],
      countData: [{
        title: 'totalWatch',
        name: '出勤人次',
        score: 0,
        unit: '人',
      },
      {
        title: 'totalWork',
        name: '提交作业数',
        score: 0,
        unit: '次',
      },
      {
        title: 'totalLearn',
        name: '学习总时长',
        score: 0,
        unit: '时',
      },
      {
        title: 'totalSign',
        name: '签到总次数',
        score: 0,
        unit: '次',
      },
      // {
      //   title: 'totalScore',
      //   name: '学员积分',
      //   score: 0,
      //   unit: '分',
      // },
      {
        title: 'speakCount',
        name: '连麦次数',
        score: 0,
        unit: '次',
      },

      {
        title: 'totalChat',
        name: '互动交流次数',
        score: 0,
        unit: '次',
      },
      ],
      nextLessonInfo: {}, //下一任务信息
      markMap: this.$store.state.course.markMap,
      downloadVisible: false,
      asyncTeacherName:'', //异步课全部授课老师
      asyncTeacherInfo:{
        name:'',
        introduce:''
      }
    };
  },
  filters: {
    // 分钟转时分秒
    transformTimeFormat(value, type) {
      // console.log(value)
      if (!value) return 0;

      if (type == 'hour' && value >= 3600) {
        return parseInt((value / 3600));
      }

      if (type == 'min' && value >= 3600) {
        return parseInt((value % 3600) / 60);
      }

      if (type == 'min' && value < 3600) {
        return parseInt(value / 60);
      }
      if (type == 'second') return parseInt(value % 60);
    },
    // 秒转分钟
    MinFormat(value, type) {
      // console.log(value)
      if (!value) return 0;
      if (type == 'min') {
        return parseInt(value / 60);
      }
      if (type == 'second') return parseInt(value % 60);
    },
    // 统计数据文字对照
    handleCountData(value, pattern, classType) {
      let contrastChart = {
        totalReserve: '预约总人数',
        totalWatch: pattern == 1 ? '观看总人数' : '出勤人数',
        totalWork: '提交作业数',
        totalLearn: '学习总时长',
        totalSign: classType == 4 ? '上台总人数' : '签到总次数',
        totalScore: '学员积分',
        speakCount: '连麦次数',
        totalChat: '互动交流次数',
      }

      return contrastChart[value]
    },
  },
  watch: {},
  methods: {
    handleDownLoad(data) {
      this.downloadVisible = data.visible;
    },
    // 通过id获取课程信息
    async getfindCourse() {
      let id = this.$route.params.id;
      let resData = await this.$Api.Course.findCourse(id);
    },
    async golive(item) {

      // classin直播课客户端内
      if (window.qt && item.classType == 5) {
        window.nativeBridge.initConfig.connect(function (config) {
          initConfig(config);
        });
        let params = {
          lessonId: item.liveLessonId
        }
        let resData = await this.$Api.Course.getClassInLivingInfo(params)
        let school = 632500; // 正式
        // let school = 101134; // 测试

        let zone = 86
        let classtype = 2
        let telephone = resData.data.phone;
        let course = resData.data.courseId;
        let lesson = resData.data.lessonId;
        if (item.mark == 2) {
          window.nativeBridge.openClassroom(zone, telephone, school, course, lesson, classtype)
        }
        return;
      }
      // 公开课
      if (item.lessonsPattern == -1) {
        // urlType 1:MD5 2:外部链接
        // 内部视频并且已开放状态 直接去异步课视频页
        if (item.urlType == 1 && item.mark === 11) {
          this.$router.push({
            // path: '/asynclive',
            path: '/newasyncLive',
            query: {
              id: item.liveLessonId,
              from: this.$route.query.from
            }
          });
        }
        // 外部链接 直接打开
        if (item.urlType == 0) {
          window.open(item.link);
        }
      }

      // 百家云同步课 & 本地异步课 & classin直播课页面唤起客户端
      switch (item.mark) {
        // 百家云同步课（直播课）
        case 2: { // 直播中
          this.downloadVisible = true;
          break;
        }
        case 3: { // 未开始
          break;
        }
        // 本地异步课
        case 11: //已开放
        case 12: { //未开放
          this.currentTab = 'Catalog'
          sessionStorage.setItem('coursePage', 'Catalog')
          window.scrollTo(0, 550)
          break;
        }
        default: {
          break;
        }
      }
    },
    //获取下一任务
    async getNextLesson() {
      let courseID = this.$route.params.id
      let resData = await this.$Api.Course.getNextLesson(courseID)
      console.log(resData);
      this.nextLessonInfo = resData.data
    },
    //获取课程信息
    async getCourseInfo() {
      let courseID = this.$route.params.id
      let resData = await this.$Api.Course.getCourseInfo(courseID)
      console.log(resData);
      this.courseInfo = resData.data;
      this.$route.meta.title = this.courseInfo.name;
      this.$store.commit('breadPageNameChange', this.courseInfo.name)
      if (this.$route.query.from != 'school') {
        this.from = this.courseInfo.lessonsPattern == 0 ? 'course' : 'courseAsync'
      }
      this.countData.map((item) => {
        if (resData.data[item.title]) {
          item.score = this.courseInfo[item.title]
        }
      })
      // 百家云课程 & 异步课
      if (this.courseInfo.classType != 5) {
        this.countData = this.countData.slice(0, 4);
      }
      // classin课程
      if (this.courseInfo.classType == 5) {
        // 不删除的数据位置
        let position = [0, 2, 4, 5];
        let newArray = [];
        for (let i = 0; i < position.length; i++) {
          newArray.push(this.countData.slice(position[i], position[i] + 1)[0]);
        }
        this.countData = newArray;
      }
    },
    async getCourseStudyInfo() {
      this.countDatas = [];
      let nameMap = {};
      // this.$route.params.id
      let resData = await this.$Api.Course.getCourseStudyInfo(this.$route.params.id);
      // console.log(resData);
      if (!resData.data || JSON.stringify(resData.data) == '[]') return;
      switch (resData.data.ctype) {
        case 0: { // 公开课
          break;
        }
        case 1: { // 异步课
          nameMap = {
            playbackTime: {
              name: '学习总时长',
              unit: '分钟',
            },
          }
          break;
        }
        case 2: { // 百家云 大班直播课 2
          nameMap = {
            liveTime: {
              name: '直播学习总时长',
              unit: '分钟',
            },
            playbackTime: {
              name: '观看回放总时长',
              unit: '分钟',
            },
            signCount: {
              name: '签到总次数',
              unit: '次',
            },
            attendanceCount: {
              name: '出勤总次数',
              unit: '次',
            },
            questionCount: {
              name: '提问次数',
              unit: '次',
            },
          }
          break;
        }
        case 3: { // 百家云 大班伪直播 3
          nameMap = {
            liveTime: {
              name: '直播学习总时长',
              unit: '分钟',
            },
            playbackTime: {
              name: '观看回放总时长',
              unit: '分钟',
            },
            questionCount: {
              name: '提问次数',
              unit: '个',
            },
          }
          break;
        }
        case 4: { // 百家云 小班课 4
          nameMap = {
            liveTime: {
              name: '直播学习总时长',
              unit: '分钟',
            },
            playbackTime: {
              name: '观看回放总时长',
              unit: '分钟',
            },
            attendanceCount: {
              name: '上台次数',
              unit: '次',
            },
            signCount: {
              name: '签到总次数',
              unit: '次',
            },
            questionCount: {
              name: '提问次数',
              unit: '次',
            },
          }
          break;
        }
        case 5: { // classin 5
          nameMap = {
            liveTime: {
              name: '直播学习总时长',
              unit: '分钟',
            },
            playbackTime: {
              name: '观看回放总时长',
              unit: '分钟',
            },
            speakCount: {
              name: '连麦次数',
              unit: '次',
            },
            chatCount: {
              name: '互动交流次数',
              unit: '次',
            },
          }
          break;
        }

        default: {
          nameMap = {
            playbackTime: {
              name: '学习总时长',
              unit: '分钟',
            },
          }
          break;
        }
      }

      for (const key in nameMap) {
        let obj = {
          title: key,
          name: nameMap[key].name,
          value: resData.data[key],
          unit: nameMap[key].unit
        }
        // if (nameMap[key].unit == '分钟') {
        //   obj.value = resData.data[key]
        // }
        this.countDatas.push(obj);
      }

    },
    // 页面初始化
    init() {
      this.getCourseInfo();
      this.getCourseStudyInfo();
      if (this.$route.query.from && this.$route.query.from == 'school') {
        this.from = 'schoolList'
        return;
      }
    },
    getAsyncTeacherName(data) {
      this.asyncTeacherName = data
    },
    getAsyncTeacherInfo(data){
      // console.log('当前课节教师信息:',data)
      this.asyncTeacherInfo = data
    }
  },
  mounted() {
    this.init();
  },
  beforeRouteLeave(to, from, next) {
    sessionStorage.removeItem("coursePage");
    next(vm => { });
  }
};
</script>

<style lang="less" scoped>
.course-detail {
  // background-color: #f9f9f9;
  padding-bottom: 105px;
  width: 990px;
  margin: 0 auto;

  // 面包屑导航
  .breadcrumb {
    margin: 41px auto 27px;
  }

  // 课程信息 start
  .course-info {
    background: #fff;
    border: 1px solid #F2F2F2;
    border-radius: 8px;
    padding: 25px 28px;
  }

  // 课程基础信息

  // 课程封面
  .cover {
    width: 389px;
    height: 230px;
    margin-right: 15px;
    border-radius: 6px;
    flex-shrink: 0;
  }

  .base-info-right {
    width: 100%;
  }

  // 课程标题部分
  .info-part {
    // margin-left: 18px;
    padding-top: 12px;

    .title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 12px;
    }

    .project-name,
    .teacher,
    .time {
      color: #999;
      font-size: 12px;
      margin-bottom: 6px;
    }

    .time {
      margin-bottom: 0px !important;
    }
  }

  // 进入直播大按钮
  .into-course {
    align-self: flex-end;
    padding: 0 22px;
    line-height: 30px;
    font-size: 14px;
    font-weight: 500;
    background-color: #508EF9;
    border-radius: 25px;

    &.btn-no {
      background-color: #c0c0c0;
      border-color: #c0c0c0;
    }
  }

  // 数据统计
  .count-data {
    margin-top: 40px;
    padding: 14px 0;
    font-size: 12px;
    text-align: center;
    color: #999;
    background-color: #FAFAFA;


    .count {
      color: #F66E39;
      font-size: 21px;
      font-weight: bold;

      i {
        font-size: 12px;
        font-weight: 300;
      }
    }

    &>div:nth-child(2) .count {
      color: #50AFF9;
    }

    &>div:nth-child(3) .count {
      color: #FFA955;
    }

    &>div:nth-child(4) .count {
      color: #3DDB9A;
    }

    &>div:nth-child(5) .count {
      color: #5370A2;
    }
  }

  // 课程信息 end
  .detail-info {
    margin-top: 32px;
  }

  .teacher-info {
    box-sizing: border-box;
    width: 279px;
    min-height: 210px;
    max-height: 290px;
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    background-color: #fff;
    padding: 0 20px;

    padding-bottom: 15px;
    overflow-y: auto;

    /*修改滚动条样式*/
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 2px;
    }

    &::-webkit-scrollbar-button {
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #bfbfbf;
      border-radius: 4px;
    }


    h4 {
      line-height: 40px;
      font-size: 13px;
      color: #555;
      border-bottom: 1px solid #f9f9f9;

      span {
        font-size: 12px;
        align-self: flex-end;
      }
    }
  }

  .name-part {
    margin: 10px 0 18px;

    .avatar {
      width: 34px;
      height: 34px;
      border-radius: 50%;

      vertical-align: middle;
    }

    .name,
    .title {
      margin-left: 12px;
      font-size: 12px;
      font-weight: 600;
    }

  }

  .discribe {
    color: #666;
    font-size: 12px;
    line-height: 24px;
    // padding-bottom: 40px;
  }





  // 四模块 start

  .catalog {
    box-sizing: border-box;
    padding: 0 35px 0 23px;
    width: 681px;
    max-height: 670px;
    overflow-y: auto;
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    background-color: #fff;

    /*修改滚动条样式*/
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 2px;
    }

    &::-webkit-scrollbar-button {
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #bfbfbf;
      border-radius: 4px;
    }

  }

  /deep/ .el-tabs__header {
    background-color: #fff;
    margin: 0;

    .el-tabs__item {
      padding: 0 20px !important;
      font-size: 14px;
      // font-weight: bold;
      color: #555555;
      height: 40px;
      line-height: 40px;

    }

    .el-tabs__nav-wrap.is-top {
      padding: 0 13px;
    }

    .el-tabs__item:hover {
      color: #508EF9;
    }

    .el-tabs__item.is-active {
      color: #508EF9;

    }
  }

  /deep/ .el-tabs__active-bar {
    height: 1px;
    background-color: #508EF9;
    padding: 0 20px;
    margin-left: -20px;
  }

  /deep/ .el-tabs__nav-wrap::after {
    height: 1px;
    background-color: #f2f2f2;
  }

  /deep/ .el-tabs__nav.is-stretch>* {
    flex: 0 0 15%;
  }

  // 四模块 end
  @media screen and (min-width:1250px) {
    width: 1200px;

    .course-info {
      padding: 27px 34px;
    }

    .cover {
      width: 455px;
      height: 256px;
      margin-right: 31px;
    }

    .info-part {
      .title {
        font-size: 19px;
        margin-bottom: 13px;
      }

      .project-name,
      .teacher,
      .time {
        font-size: 14px;
        margin-bottom: 8px;
      }
    }

    .into-course {
      padding: 0 26px;
      font-size: 15px;
      line-height: 36px;
    }

    .count-data {
      font-size: 13px;

      .count {
        font-size: 25px;
      }
    }

    .teacher-info {
      width: 340px;
      padding: 0 25px;
      padding-bottom: 20px;

      h4 {
        font-size: 16px;
      }
    }

    .name-part {
      margin: 12px 0 25px;

      .avatar {
        width: 41px;
        height: 41px;
      }

      .name,
      .title {
        margin-left: 15px;
        font-size: 14px;
      }

    }

    .discribe {
      font-size: 13px;
    }

    .catalog {
      width: 828px;
      padding: 0 42px 0 28px;
    }

    /deep/ .el-tabs__header {
      background-color: #fff;
      margin: 0;

      .el-tabs__item {
        padding: 0 25px !important;
        font-size: 16px;
        height: 48px;
        line-height: 48px;
      }
    }

    /deep/ .el-tabs__active-bar {
      padding: 0 25px;
      margin-left: -25px;
    }
  }
}

/*媒体查询*/
</style>